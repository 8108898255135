<div class="breadcrumb">
  <a [routerLink]="['/category', content.category]">{{ content.category }}</a>
  <mat-icon fontIcon="caret-right" />
  <a [routerLink]="['/category', content.category, content.type]">
    {{ content.type }}
  </a>
  <mat-icon fontIcon="caret-right" />
  <span>{{ content.title }}</span>
</div>

<section>
  <article>
    <header>
      <div class="thumbnail-container">
        <img
          [ngSrc]="content.thumbnails[0].fileId"
          fill
          priority
          [attr.alt]="'Thumbnail for ' + content.title" />

        <div class="avatar">
          <div class="avatar-content">
            <img
              [ngSrc]="content.avatar.fileId"
              fill
              priority
              [attr.alt]="'Avatar for ' + content.title" />
          </div>
        </div>
      </div>

      <div class="header-content">
        <div class="header-content-actions">
          <!-- <button class="btn accent">
            <mat-icon fontIcon="spotify-logo" />
            <div>
              <span class="small">Listen on</span>
              Spotify Podcast
            </div>
          </button>
          <button class="btn">
            <mat-icon fontIcon="microphone" />
            <div>
              <span class="small">Listen on</span>
              Apple Podcast
            </div>
          </button> -->
        </div>

        <div class="header-content-text">
          <h1>{{ content.title }}</h1>
          @if (content.tags) {
            <div class="tags">
              @for (tag of content.tags; track tag._id) {
                <a class="mark" [href]="tagToLink(tag.slug)">{{ tag.tag }}</a>
              }
            </div>
          }
        </div>
      </div>
    </header>

    <div class="content">
      <h2>About {{ content.title }}</h2>
      <div [innerHTML]="content.description"></div>
    </div>

    <!-- <pre>{{ content | json }}</pre> -->
  </article>

  <aside>
    <!-- hello -->
  </aside>
</section>
