import { NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router, RouterLink } from '@angular/router';
import { catchError, of, tap } from 'rxjs';
import { PostResponse, Tag } from 'src/app/services/content/content.model';
import { ContentService } from 'src/app/services/content/content.service';

export const resolveSinglePost = {
  content: (route: ActivatedRouteSnapshot) => {
    const router = inject(Router);
    const contentService = inject(ContentService);
    const meta = inject(Meta);
    const title = inject(Title);

    return contentService.getContentBySlug(route.params['postId']).pipe(
      tap(res => {
        title.setTitle(res.title);
        meta.addTags([
          {
            name: 'description',
            content: res.description,
          },
        ]);
      }),
      catchError(err => {
        console.error('home err: ', err);
        router.navigate(['/404']);

        return of(null);
      })
    );
  },
};

@Component({
  selector: 'app-single-post',
  standalone: true,
  imports: [RouterLink, MatIconModule, NgOptimizedImage],
  templateUrl: './single-post.component.html',
  styleUrls: ['./single-post.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class SinglePostComponent {
  @Input() content!: PostResponse;

  tagToLink(slug: Tag['slug']) {
    console.log(slug);

    return '/tag/' + slug;
  }
}
